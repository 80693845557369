import React from "react";
import Card from "../components/Card";
import Layout from "../components/layout/Layout";
import SplitSection from "../components/SplitSection";
import menu from "../svg/TakeAwayMenu.png";
import homePage from "../svg/homepage.png";
import banner from "../svg/yogost_banner.png";
import newStore from "../svg/newStore.png";
import purpleRiceYogurt from "../svg/purpleRiceYogurt.jpg";
import strawberryLemon from "../svg/strawberryLemon.jpg";
import riceMango from "../svg/ricemango.jpg";
import aboutUs from "../svg/aboutus.png";
import ContactForm from "../components/contactForm";
import { Helmet } from "react-helmet";

export default () => (
  <div className="application">
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Hey! I am Yogost Australia Yogurt Purple Rice. Est 2017. Best Yogurt drink
      Location: Perth Northbridge WA Australia, East Victoria Park WA Australia, Adelaide SA Australia"
      />
      <meta
        name="location-wa"
        content="yogost northbridge perth WA australia"
      />
      <meta
        name="location-wa"
        content="yogost northbridge east victoria park WA australia"
      />
      <meta name="location-sa" content="yogost adelaide SA australia" />
      <meta
        name="google-site-verification"
        content="4Uf5--avv2q3h-GUW1JNdmzqoGMu1ZOM0GYlZMtMNJo"
      />
      <title>Hey! I am Yogost Australia Yogurt & Rice</title>
      <link rel="canonical" href="https://www.yogost.com.au" />
    </Helmet>
    <Layout>
      <section id="home" className="pt-32">
        <div className="container mx-auto px-8 lg:flex">
          <div className="lg:w-1/2 lg:pt-40 sd:pt-10">
            <img src={banner} alt="yogost-banner" />
          </div>
          <div className="lg:w-1/2 sd: pt-10">
            <img src={homePage} alt="homepage" />
          </div>
        </div>
      </section>

      <div className="pt-64 grid place-content-center">
        <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <a href="#contactus">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              <img src={newStore} alt="yogost-banner" />
            </h5>
          </a>
          <div className="grid place-content-center">
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              WA Yogost New Shop Opportunity
            </p>
            <a
              href="#contactus"
              class="flex justify-center px-12 py-4 text-sm font-medium text-white bg-blue-200 rounded-lg hover:bg-blue-250 focus:ring-4 focus:outline-none focus:ring-blue-200 dark:bg-blue-300 dark:hover:bg-blue-300 dark:focus:ring-blue-400"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>

      <div className="pt-32 pb-40">
        <h2
          id="menu"
          className="pt-32 pb-5 text-center text-3xl lg:text-5xl font-semibold"
        >
          Menu
        </h2>
        <img className="mr-auto ml-auto block" src={menu} alt="menu" />
        <h2 className="text-center pt-20 text-3xl lg:text-5xl font-semibold">
          Our Top 3 Drinks
        </h2>
        <div className="flex flex-col sm:flex-row sm:mt-12 text-center">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <img src={purpleRiceYogurt} alt="purple-rice" />
              <p className="font-semibold text-xl pt-3">Purple Rice Yogurt</p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <img src={strawberryLemon} alt="strawberry-lemon" />
              <p className="font-semibold text-xl pt-3">
                Strawberry Lemon Drop
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <img src={riceMango} alt="rice-mango" />
              <p className="font-semibold text-lg pt-3">Rice Mango Romance</p>
            </Card>
          </div>
        </div>
      </div>

      <h2
        id="stores"
        className="text-3xl pb-5 lg:text-5xl font-semibold text-center"
      >
        Stores
      </h2>
      <h3 className="text-2xl font-semibold leading-tight text-center">
        Western Australia
      </h3>
      <div className="md:pl-48 pb-10">
        {
          <SplitSection
            primarySlot={
              <div className="">
                <h3 className="pt-5 text-2xl font-semibold leading-tight">
                  44/188 Newcastle Street
                </h3>
                <h3 className="text-1xl font-semibold leading-tight">
                  Northbridge WA
                </h3>
                <p className="mt-4 text-xl font-light leading-relaxed font-semibold">
                  Trading Hours:
                </p>
                <p className="text-xl font-light leading-relaxed">
                  Monday 11am–9:15pm
                </p>
                <p className="text-xl font-light leading-relaxed">
                  Tuesday 11am–9:15pm
                </p>
                <p className="text-xl font-light leading-relaxed">
                  Wednesday 11am–9:15pm
                </p>
                <p className="text-xl font-light leading-relaxed">
                  Thursday 11am–9:15pm
                </p>
                <p className="text-xl font-light leading-relaxed">
                  Friday 11am–10:15pm
                </p>
                <p className="text-xl font-light leading-relaxed">
                  Saturday 11am–10:15pm
                </p>
                <p className="text-xl font-light leading-relaxed">
                  Sunday 11am–9:15pm
                </p>
              </div>
            }
            secondarySlot={
              <iframe
                title="northbridge"
                style={{ width: "350px", height: "300px" }}
                src="https://www.google.com/maps/embed?pb=!4v1593522342956!6m8!1m7!1soa8y8uwRyfpBUFwsDPN2lA!2m2!1d-31.94657303707685!2d115.861842459097!3f36.475009218777956!4f-15.847904807881022!5f0.7820865974627469"
              ></iframe>
            }
          />
        }
      </div>

      <div className="md:pl-48 pb-10">
        {
          <SplitSection
            primarySlot={
              <div className="">
                <h3 className="pt-5 text-2xl font-semibold leading-tight">
                  Shop 5, 1009 Albany Highway
                </h3>
                <h3 className="pt-2 text-1xl font-semibold leading-tight">
                  East Victoria Park
                </h3>
                <p className="mt-4 text-xl font-semibold leading-relaxed">
                  Trading Hours:
                </p>
                <p className="text-xl font-light leading-relaxed">
                  Monday - Sunday 11am – 9:30pm
                </p>
              </div>
            }
            secondarySlot={
              <iframe
                title="vic park"
                style={{ width: "350px", height: "300px" }}
                src="https://www.google.com/maps/embed?pb=!4v1604927042269!6m8!1m7!1sRsp25ZZdhAigiULwaOcUXQ!2m2!1d-31.99261268045091!2d115.9111302793824!3f315.6935196136998!4f-15.047221871703428!5f0.7820865974627469"
              ></iframe>
            }
          />
        }
      </div>

      <h3 className="text-2xl font-semibold leading-tight text-center">
        South Australia
      </h3>
      <div className="md:pl-48">
        <SplitSection
          primarySlot={
            <div className="">
              <h3 className="text-2xl font-semibold leading-tight">
                7 Moonta St
              </h3>
              <h3 className="text-1xl font-semibold leading-tight">
                Adelaide, SA
              </h3>
              <p className="mt-8 text-xl font-semibold leading-relaxed">
                Trading Hours:
              </p>
              <p className="text-xl font-light leading-relaxed">
                Monday - Friday 11:30am–10:30pm
              </p>
              <p className="text-xl font-light leading-relaxed">
                Saturday - Sunday 11am–10:30pm
              </p>
            </div>
          }
          secondarySlot={
            <iframe
              title="adelaide-location"
              style={{ width: "350px", height: "300px" }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.09254495633!2d138.5958566!3d-34.929215899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0cfded807c3a5%3A0xf517bcdceab83e7c!2sHey*21%20I%20am%20Yogost!5e0!3m2!1sen!2sau!4v1593523091880!5m2!1sen!2sau"
            ></iframe>
          }
        />
      </div>

      <br />
      <h3 className="text-2xl font-semibold leading-tight text-center">
        Queensland
      </h3>
      <div className="md:pl-48">
        <SplitSection
          primarySlot={
            <div className="">
              <h3 className="text-2xl font-semibold leading-tight">
                Shop K4 - Sunnybank Plaza
              </h3>
              <h4 className="text-1xl font-semibold leading-tight">
                Cnr Mains Rd & McCullough St
              </h4>
              <h3 className="text-1xl font-semibold leading-tight">
                Sunnybank, QLD
              </h3>
              <p className="mt-8 text-xl font-semibold leading-relaxed">
                Trading Hours:
              </p>
              <p className="text-xl font-light leading-relaxed">
                Monday - Sunday 10:00am-9:30pm
              </p>
            </div>
          }
          secondarySlot={
            <iframe
              title="adelaide-location"
              style={{ width: "350px", height: "300px" }}
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14147.012926942369!2d153.064236!3d-27.570168!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9144d9ac6f4ad7%3A0xdc3c79ad5592ab09!2sSunny%20Park%20Shopping%20Centre!5e0!3m2!1sen!2sau!4v1618641112274!5m2!1sen!2sau"
            ></iframe>
          }
        />
      </div>
      <section className="py-20 lg:py-20">
        <div className="container mx-auto">
          <div className="mt-5 container mx-auto px-16 items-center flex flex-col lg:flex-row">
            <div className="lg:w-1/3 ">
              <img src={aboutUs} alt="yogost-about-us" />
            </div>
            <div className="sm: pt-10 lg:w-2/3 lg:pl-10">
              <p className="text-xl font-light leading-relaxed text-justify">
                <h2
                  id="aboutus"
                  className="pt-32 text-center text-3xl lg:text-5xl font-semibold"
                >
                  About us
                </h2>
                Hey! I Am Yogost is a global brand that gives a healthier option
                to refresh and indulge with its all-natural yogurt drink made
                with nutritious purple rice and real fruits. Since it was
                established in 2017, it has brought the beverage market to the
                next, healthier level. It is massively growing all over the
                world with at least 71 stores including those in Australia, New
                Zealand, Indonesia, Hong Kong, China, Canada, Malaysia, and
                Philippines! Yogost brings the best of both worlds: satisfying
                your sweet craving, and keeping you on track with your health
                goals. There is no need to deprive yourself and feel guilty for
                having “cheat days.” With Yogost, you can indulge everyday.{" "}
              </p>

              <br />
              <br />
              <p className="font-bold text-center">Our Story</p>
              <br />
              <p className="text-xl font-light leading-relaxed text-center">
                Have you ever had an unexpectedly beautiful outcome borne of an
                accident?
              </p>
              <p className="text-xl font-light leading-relaxed text-justify">
                Our story began after inadvertently mixing milk into a cup of
                yogurt. After tasting it, we discovered a new taste that was
                surprisingly refreshing and hence, the idea for a new drink was
                born! Since then, we have begun an adventure in search of the
                best yogurt drink in the world! We have diligently experimented
                with hundreds of options - from the selection of yogurt, milk,
                fresh ingredients, to the taste and ratio balance to produce
                some of our top-selling drinks today! To date, we are still on
                this mission of constantly refining and developing our recipes
                to keep the drinks up to another level of excellence. Fresh
                everyday, that is our motive and our promise for our valued
                customers.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="contactus"
        className="container mx-auto my-12 pt-12 pb-4 bg-blue-100 rounded-lg text-center"
      >
        <h3 className="text-3xl font-semibold">Contact Us</h3>
        <p className="mt-8 text-lg">
          Thank you for visiting Yogost. Your comments and suggestions are
          welcome and will assist us in continously improving our brand.
        </p>
        <br />
        <p className="text-lg ">
          Please send us your comments in the space below
        </p>

        <ContactForm />
        <p className="mt-8"></p>
      </section>
    </Layout>
  </div>
);
