import React, { Component } from "react";
import { navigate } from "gatsby";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.ContactForm = React.createRef();
    this.state = {};
  }
  encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = this.ContactForm.current;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        navigate(form.getAttribute("action"));
      })
      .catch((error) => {
        console.log("====================================");
        console.log(`error in submiting the form data:${error}`);
        console.log("====================================");
      });
  };
  render() {
    return (
      <form
        class="lg:pr-48 pt-6"
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        ref={this.ContactForm}
        action="/success"
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </p>
        <div class="">
          <div class="md:flex items-center mb-6">
            <div class="md:w-1/3">
              <label
                class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                for="inline-name"
              >
                {" "}
                Subject
              </label>
            </div>
            <div class="relative">
              <div class="inline-block">
                <select
                  onChange={this.handleChange}
                  name="subject"
                  required
                  class="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline z-30"
                >
                  <option value="" disabled selected>
                    Select your option
                  </option>
                  <option>WA Yogost New Store</option>
                  <option>Business Enquiries</option>
                  <option>Feedback</option>
                  <option>Complaint</option>
                  <option>Other</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="md:flex items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-name"
            >
              {" "}
              Name
            </label>
          </div>
          <div class="md:w-2/3">
            <input
              class="bg-white-100 appearance-none border-2 border-gray-200 rounded
        w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none
        focus:bg-white focus:border-purple-500 rounded shadow"
              type="text"
              name="name"
              placeholder="Name"
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-email"
            >
              Email
            </label>
          </div>
          <div class="md:w-2/3">
            <input
              class="bg-white-100 appearance-none border-2 border-gray-200 rounded
        w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none
        focus:bg-white focus:border-purple-500 rounded shadow"
              type="email"
              name="email"
              placeholder="Email"
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-email"
            >
              Contact Number
            </label>
          </div>
          <div class="md:w-2/3">
            <input
              class="bg-white-100 appearance-none border-2 border-gray-200 rounded
        w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none
        focus:bg-white focus:border-purple-500 rounded shadow"
              type="text"
              name="contactNumber"
              placeholder="Mobile / Landline"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label
              class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
              for="inline-message"
            >
              Message
            </label>
          </div>
          <div class="md:w-2/3">
            <textarea
              class="bg-white-100 appearance-none border-2 border-gray-200 rounded
        w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none
        focus:bg-white focus:border-purple-500 rounded shadow"
              name="message"
              placeholder="Your Message"
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div class="md:flex">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button
              class="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    );
  }
}
export default ContactForm;
