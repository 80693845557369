import React from 'react';

const SplitSection = ({ id, primarySlot, secondarySlot, reverseOrder }) => (
  <section id={id} className="py-5">
    <div className="container mx-auto px-16 items-center flex flex-col lg:flex-row">
      <div className="lg:w-1/2">{primarySlot}</div>
      <div className="mt-10 sm:w-1/2 sm:mr-56">
        {secondarySlot}
      </div>
    </div>
  </section>
);

export default SplitSection;
